<template>
  <div>
    <h2>Check Personalizations</h2>
    <p>This page allows you to check and submit personalizations for leads that haven't been added to the CRM.</p>
    
    <!-- Table to display leads -->
    <table v-if="leads.length > 0">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>URL</th>
          <th>Instrument</th>
          <th>Language</th>
          <th>Personalization</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(lead, index) in leads" :key="index">
          <td>{{ lead.first_name }}</td>
          <td>{{ lead.last_name }}</td>
          <td>
            <!-- If URL is empty, show "No website" text, otherwise make it a clickable link -->
            <template v-if="lead.url">
              <a :href="lead.url" target="_blank" rel="noopener noreferrer" @click.prevent="openInSmallWindow(lead.url)">
                Open
              </a>
            </template>
            <template v-else>
              No website
            </template>
          </td>
          <td>{{ lead.instrument }}</td>
          <td>{{ lead.language }}</td>
          <td>
            <!-- Disable the personalization input after it's submitted -->
            <input
              type="text"
              v-model="lead.personalization"
              :disabled="lead.personalizationSubmitted"
              placeholder="Enter personalization"
            />
          </td>
          <td>
            <!-- Show the checkmark button if personalization is not submitted yet -->
            <template v-if="!lead.personalizationSubmitted">
              <button @click="submitPersonalization(lead, index)">✔️</button>
            </template>
            <!-- Show the red remove button after personalization is submitted -->
            <template v-else>
              <button class="remove-btn" @click="removePersonalization(index)">❌</button>
            </template>
          </td>
        </tr>
      </tbody>
    </table>

    <p v-else>No leads found to check personalizations.</p>
  </div>
</template>

<script>
export default {
  name: 'CheckPersonalizations',
  data() {
    return {
      leads: [], // Holds the leads data fetched from the API
    };
  },
  methods: {
    // Fetch the leads from the API when the component is created
    async fetchLeads() {
      try {
        const response = await fetch('https://dreher-media-leads.onrender.com/retrieve-personalizations');
        const data = await response.json();
        this.leads = data; // Assuming the API response returns an array of leads
      } catch (error) {
        console.error('Error fetching leads:', error);
      }
    },

    // Method to submit a personalization for a specific lead
    async submitPersonalization(lead, index) {
      try {
        const response = await fetch('https://dreher-media-leads.onrender.com/submit-personalization', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: lead.email,
            first_name: lead.first_name,
            personalization: lead.personalization,
          }),
        });

        if (response.ok) {
          alert(`Personalization for ${lead.first_name} ${lead.last_name} submitted successfully!`);
          // Set flag to mark the personalization as submitted and disable the input
          this.leads[index].personalizationSubmitted = true;
        } else {
          alert('Failed to submit personalization.');
        }
      } catch (error) {
        console.error('Error submitting personalization:', error);
      }
    },

    // Remove the personalization and allow the field to be editable again
    removePersonalization(index) {
      this.leads[index].personalization = ''; // Clear the personalization
      this.leads[index].personalizationSubmitted = false; // Make the field editable again
    },

    // Open the URL in a small window to allow fast return to the interface
    openInSmallWindow(url) {
      window.open(url, '_blank', 'width=800,height=600,scrollbars=yes,resizable=yes');
    },
  },
  // Fetch the leads when the component is created
  created() {
    this.fetchLeads();
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
}

input[type="text"] {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

button {
  padding: 5px 10px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #358960;
}

a {
  color: #42b983;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.remove-btn {
  background-color: red;
  padding: 5px 10px;
  border-radius: 3px;
  color: white;
  border: none;
  cursor: pointer;
}

.remove-btn:hover {
  background-color: darkred;
}
</style>
