<template>
  <div class="form-container">
    <h2>Insert Leads</h2>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="email">E-Mail</label>
        <input
          type="email"
          id="email"
          v-model="email"
          @blur="checkEmail"
          required
        />
        <span v-if="emailExists" class="error">Lead already exists in CRM.</span>
      </div>

      <div class="form-group">
        <label for="gender">Gender</label>
        <select id="gender" v-model="gender" required>
          <option disabled value="">Select Gender</option>
          <option value="Female">Female</option>
          <option value="Male">Male</option>
        </select>
      </div>

      <div class="form-group">
        <label for="firstName">First Name</label>
        <input type="text" id="firstName" v-model="firstName" required />
      </div>

      <div class="form-group">
        <label for="lastName">Last Name</label>
        <input type="text" id="lastName" v-model="lastName" required />
      </div>

      <div class="form-group">
        <label for="url">URL</label>
        <input type="url" id="url" v-model="url" />
      </div>

      <div class="form-group">
        <label for="instrument">Instrument</label>
        <select id="instrument" v-model="instrument" required>
          <option disabled value="">Select Instrument</option>
          <option value="Viola">Viola</option>
          <option value="Composing">Composing</option>
          <option value="Clarinet">Clarinet</option>
          <option value="Voice">Voice</option>
          <option value="Violin">Violin</option>
          <option value="Piano">Piano</option>
          <option value="Conducting">Conducting</option>
          <option value="Percussion">Percussion</option>
          <option value="Flute">Flute</option>
          <option value="Cello">Cello</option>
          <option value="Bassoon">Bassoon</option>
          <option value="Trombone">Trombone</option>
          <option value="Accordion">Accordion</option>
          <option value="Acting">Acting</option>
          <option value="Bandoneon">Bandoneon</option>
          <option value="Choreographer">Choreographer</option>
          <option value="Directing">Directing</option>
          <option value="Double Bass">Double Bass</option>
          <option value="Euphonium">Euphonium</option>
          <option value="Guitar">Guitar</option>
          <option value="Harp">Harp</option>
          <option value="Harpsichord">Harpsichord</option>
          <option value="Horn">Horn</option>
          <option value="Lute">Oboe</option>
          <option value="Organ">Organ</option>
          <option value="Percussion">Percussion</option>
          <option value="Recorder">Recorder</option>
          <option value="Saxophone">Saxophone</option>
          <option value="Trumpet">Trumpet</option>
          <option value="Tuba">Tuba</option>
          <option value="Vibraphone">Vibraphone</option>
          <option value="Viola de Gamba">Viola de Gamba</option>
        </select>
      </div>

      <div class="form-group">
        <label for="addedBy">Added By</label>
        <select id="addedBy" v-model="addedBy" required>
          <option disabled value="">Select Initials</option>
          <option value="PE">PE</option>
          <option value="SR">SR</option>
        </select>
      </div>

      <div class="form-group">
        <label for="language">Language</label>
        <select id="language" v-model="language" required>
          <option disabled value="">Select Language</option>
          <option value="EN">English</option>
          <option value="DE">German</option>
        </select>
      </div>

      <div class="form-group">
        <label for="source">Source</label>
        <select id="source" v-model="source" required>
          <option disabled value="">Select Source</option>
          <option value="Operabase">Opera Base</option>
          <option value="Instagram">Instagram</option>
          <option value="Aktive Programme">Aktive Programme</option>
        </select>
      </div>

      <div class="button-container">
        <button type="submit" :disabled="emailExists || loading">
          Submit
        </button>
        <div v-if="loading" class="loading-spinner"></div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      gender: '',
      firstName: '',
      lastName: '',
      url: '',
      instrument: '',
      addedBy: '',
      language: '',
      source: '',
      emailExists: false, // To track if the email already exists
      loading: false, // To track loading state
    };
  },
  methods: {
    async checkEmail() {
      if (this.email) {
        try {
          const response = await fetch(
            'https://dreher-media-leads.onrender.com/check-email',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email: this.email }),
            }
          );

          const data = await response.json();
          this.emailExists = data.exists; // If email exists, set the flag to true
        } catch (error) {
          console.error('Error checking email:', error);
          this.emailExists = false; // If there's an error, allow the form to proceed
        }
      }
    },
    async handleSubmit() {
      if (this.emailExists) {
        alert('Email already exists in the system. No need to enter it again.');
        return;
      }

      this.loading = true; // Start loading

      try {
        const response = await fetch('https://dreher-media-leads.onrender.com/add-to-sheets', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: this.email,
            gender: this.gender,
            first_name: this.firstName,
            last_name: this.lastName,
            url: this.url,
            instrument: this.instrument,
            added_by: this.addedBy,
            language: this.language,
            source: this.source,
          }),
        });

        const data = await response.json();
        if (data.success) {
          alert(data.message);
          this.resetForm(); // Reset form after success
        } else {
          alert('Error adding data to Google Sheet.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred while submitting the form.');
      } finally {
        this.loading = false; // Stop loading
      }
    },
    resetForm() {
      this.email = '';
      this.gender = '';
      this.firstName = '';
      this.lastName = '';
      this.url = '';
      this.instrument = '';
      this.language = '';
      this.source = '';
      this.emailExists = false;
    },
  },
};
</script>

<style scoped>
.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 10px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type='text'],
input[type='email'],
input[type='url'],
select {
  width: 100%;
  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  background-color: #42b983;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background-color: #888;
  cursor: not-allowed;
}

.loading-spinner {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #42b983;
  animation: spin 1s ease-in-out infinite;
  margin-left: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.error {
  color: red;
  font-size: 0.9em;
}
</style>
