<template>
  <div class="dashboard-container">
    <div class="sidebar">
      <nav>
        <ul>
          <li>
            <router-link to="/dashboard" exact-active-class="router-link-exact-active">
              Dashboard
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/add-leads">
              Add Leads
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/check-personalizations">
              Check Personalizations
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/add-leads-crm">
              Add Leads to CRM
            </router-link>
          </li>
        </ul>
      </nav>

      <div class="sidebar-footer">
        <button @click="handleLogout">Logout</button>
      </div>
    </div>

    <div class="content">
      <!-- Conditionally display the dashboard content only on the main dashboard page -->
      <div v-if="$route.path === '/dashboard'" class="dashboard-content">
        <h1>Welcome to the Dashboard</h1>
        <p>This is some dummy content that will be displayed on the main dashboard page.</p>
      </div>

      <!-- Dynamic subpage content -->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '../stores/auth'; // Import the auth store

export default {
  name: 'UserDashboard',
  setup() {
    const authStore = useAuthStore();

    return { authStore };
  },
  methods: {
    handleLogout() {
      this.authStore.logout(); // Clear the login state
      this.$router.push('/login'); // Redirect to login page
    }
  }
};
</script>

<style scoped>
/* Main layout */
.dashboard-container {
  display: flex;
  min-height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent both horizontal and vertical scrolling */
  flex-direction: row;
  align-items: stretch;
}

/* Sidebar styling */
.sidebar {
  width: 250px;
  background-color: #f5f5f5;
  padding: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box; /* Ensure padding doesn't affect total width */
}

/* Sidebar links */
.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  text-decoration: none;
  color: #333;
}

.sidebar ul li a.router-link-exact-active {
  font-weight: bold;
}

/* Sidebar footer */
.sidebar-footer {
  margin-top: auto; /* Stick the logout button at the bottom */
}

.sidebar-footer button {
  display: block;
  width: 100%;
  background-color: #42b983;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar-footer button:hover {
  background-color: #358960;
}

/* Content section styling */
.content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto; /* Allow vertical scrolling in content if needed */
  box-sizing: border-box;
}

/* Dummy content styling for dashboard home */
.dashboard-content {
  margin-bottom: 40px; /* Space for dummy content */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .dashboard-container {
    flex-direction: column; /* Stack sidebar on top */
  }

  .sidebar {
    width: 100%; /* Sidebar takes full width on small screens */
    padding: 10px;
    flex-direction: row;
    justify-content: space-between;
  }

  .sidebar ul {
    display: flex;
    justify-content: space-around; /* Spread links across */
  }

  .content {
    padding: 10px; /* Less padding on smaller screens */
  }
}

@media (max-width: 480px) {
  .sidebar ul li {
    margin-bottom: 5px;
  }

  .sidebar-footer button {
    padding: 8px;
  }

  .content {
    padding: 5px;
  }
}
</style>
