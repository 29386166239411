import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'

import AddLeads from './components/AddLeads.vue'
import UserLogin from './components/UserLogin.vue'
import UserDashboard from './components/UserDashboard.vue'
import CheckPersonalizations from './components/CheckPersonalizations.vue'
import AddLeadsToCRM from './components/AddLeadsToCRM.vue'
import { useAuthStore } from './stores/auth'

const routes = [
    {path: '/', component: AddLeads},
    {path: '/login', component: UserLogin},
    {
        path: '/dashboard', 
        component: UserDashboard,
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            if (authStore.loggedIn) {
                next();
            } else {
                next('/login');
            }
        },
        children: [
            {
              path: 'add-leads',
              name: 'AddLeads',
              component: AddLeads,
            },
            {
              path: 'check-personalizations',
              name: 'CheckPersonalizations',
              component: CheckPersonalizations,
            },
            {
              path: 'add-leads-crm',
              name: 'AddLeadsToCRM',
              component: AddLeadsToCRM,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const app = createApp(App);
app.use(createPinia())
app.use(router);
app.mount('#app');
